<template v-cloak v-if="dumpster">
  <transition name="fade" mode="out-in">
    <section class="price-item">
      <div class="row no-margin-bottom"
        :data-key="dumpster.service_area_id"
        :data-hover-id="dumpster.service_area_id"
        @mouseenter="toggleHover(dumpster.service_area_id)"
        @mouseleave="toggleHover()">
        <div v-if="preferences.searchType !== 'hauler'" class="col s12">
          <div class="hauler-info-header" :class="{outOfNetwork: dumpster.hauler.type === 'outOfNetwork'}">
            <div class="title-left">
              <div
                class="color-tab"
                :style="'background-color:'+dumpster.service_area_color+''">
                  &nbsp;
              </div>
              <div class="ns-name">{{netsuiteName}}</div>
            </div>
            <div v-if="dumpster.hauler.type !== 'outOfNetwork'" class="gross-margin"><strong>GM</strong> ${{profitMargin}} | <strong>Sold</strong> {{quantitySold}}</div>
          </div>
        </div>
      </div>

        <!-- start alerts -->
      <div v-if="dumpster.alerts.length" class="row flex no-margin-bottom">
        <ul class="col s12" style="margin:0">
          <li v-for="alert of dumpster.alerts" :key="alert.id">
            <alert-box
            class="primary-alert-text"
            :message="alert.text">
              <template v-slot:subtitle>
                <div class="details">
                  <small>
                    <strong>Created At: </strong> {{ fmt(alert.created_at) }} |

                    <strong>Created By: </strong> <user-name :userId="alert.created_by" /> |

                    <strong>Updated At: </strong> {{ fmt(alert.updated_at) }} |

                    <strong>Updated By: </strong> <user-name :userId="alert.updated_by" />
                  </small>
                </div>
              </template>
            </alert-box>
          </li>
        </ul>
      </div>
      <!-- end alerts -->

      <div
        v-if="nextDeliverable.serviceAreaExceptionInfo.description"
        class="service-area-alert"
      >
        <div><i class="fa fa-calendar-times-o" /> No Service: {{nextDeliverable.serviceAreaExceptionInfo.description}}</div>
        <div class="details">As of: {{nextDeliverable.serviceAreaExceptionInfo.updated_date}}</div>
      </div>

      <!-- start notes -->
      <div
        v-if="dumpster.product_notes"
        class="price-note row no-margin-bottom"
        :key="dumpster.id">
          <div class="col s12">
            <alert-box :message="dumpster.product_notes" severity="note"/>
          </div>
      </div>
      <!--end notes-->

      <div class="row flex dumpster-price">
        <div v-if="dumpster" class="col s3 dumpster-size center-align no-padding-right" >
            <div class="sale-volume">
              {{(dumpster.size.name) ? dumpster.size.name : 'No Size'}}
              <div v-if="dumpster.actual_volume" class="actual-volume">
                <em>Actual Volume ({{dumpster.actual_volume}})</em>
              </div>

              <div v-if="dumpster.length" class="custom-dimensions">
                  <em>Custom Dimensions (LxWxH)</em><br/>
                  {{dumpster.length}}' x
                  {{dumpster.width}}' x
                  {{dumpster.height}}'
              </div>
            </div>

            <div class="waste-type">
              {{dumpster.waste_type.name}}
            </div>

            <div class="copy-update">
              <button
                :data-clipboard-text="netsuiteName"
                :class="[{'yellow-btn': dumpster.mako_preferred }, `btn clip-btn-${dumpster.id}`]">
                  <i v-if="dumpster.mako_preferred" class="material-icons">star</i>
                  copy to clipboard
              </button>
              <div v-if="canUseCalculator">
                <a @click="customizePrice">Customize Price</a>
                <base-modal
                    :show="showCalculator"
                    @close="showCalculator = false"
                >
                  <PriceCalculator
                    slot="modalContent"
                    :item="dumpster"
                    :title="netsuiteName"
                  ></PriceCalculator>
                </base-modal>
              </div>
              <div class="updated-tag">
                <small><i>Last Updated: {{fmt(dumpster.updated_at)}}</i></small>
              </div>
            </div>
          </div><!-- col s3-->

          <div class="col s9 dumpster-price-body no-padding-left">
            <div class="grid five-col scan-bar" v-if="!hasSpecialDebris">
              <div
              :class="fillExceptionClass('yard_waste')"
              :data-tooltip="dumpster.yard_waste_note">
                Yard Waste
              </div>
              <div
              :class="fillExceptionClass('stumps')"
              :data-tooltip="dumpster.stumps_note">
                Stumps
              </div>
              <div
              :class="fillExceptionClass('appliances')"
              :data-tooltip="dumpster.appliances_note">
                Appliances
              </div>
              <div
              :class="fillExceptionClass('electronics')"
              :data-tooltip="dumpster.electronics_note">
                Electronics
              </div>
              <div
              :class="fillExceptionClass('mattress')"
              :data-tooltip="dumpster.mattress_note">
                Mattress/Boxspring
              </div>
            </div>
            <div class="grid five-col scan-bar" v-if="hasSpecialDebris">
              <div
                v-for="(special, index) in specialUseFields"
                :key="index"
                :class="fillExceptionClass(special)"
                :data-tooltip="dumpster[`${special+'_note'}`]">
                  {{special | convertYard | capitalize}}
                </div>
            </div>

          <div class="grid three-col cost-info">
            <div v-if='price == null'>
              <b>Haul + Disposal</b>
              <span class='pricing-error'>Unable to fetch price</span>
            </div>
            <div v-else
              class="tooltipped"
              data-html="true"
              :data-tooltip="getCostTooltip(price, dumpster.cost, true, dumpster.cost_structure_note)">
                <span class="dumpster-price">
                  {{ priceUsd }}
                </span>
                <img
                  v-if="typeof dumpster.tax_rate === 'undefined' || dumpster.tax_rate > 0"
                  src="../assets/images/tax_icon.svg"
                  alt="+tax"
                />
                <span class="context overage">
                  {{ (dumpster.cost_structure) ?
                  dumpster.cost_structure.name :
                  'no cost structure'  }}
                </span>
            </div>

            <div v-if='overagePrice == null'>
                <b>{{ dumpster.tonnage }} T</b>
                <span class="overage pricing-error">
                  Unable to fetch overage
                </span>
            </div>
            <div v-else
              class="tooltipped"
              data-html="true"
              :data-tooltip="getCostTooltip(overagePrice, overageCost, false)">
              <b>{{ dumpster.tonnage }} T</b>
              <span class="context overage">+{{ overagePriceFixed }} / ton</span>
            </div>

            <div v-if='extensionPrice == null'>
              <b>{{dumpster.rental_period}} days</b>
              <span class="overage pricing-error">
                  Unable to fetch extension
                </span>
            </div>
            <div v-else
            class="tooltipped"
            data-html="true"
            :data-tooltip="getCostTooltip(extensionPrice, extensionCost, false, dumpster.extra_day_cost_note)">
                <b>{{ dumpster.rental_period }} days</b>
                <span class="context overage">
                    +{{ extensionPriceFixed }} / day
                </span>
            </div>
          </div>

          <div class="col s12">
            <p v-if="dumpster.other_note">
              <b>Other Exceptions:</b> {{ dumpster.other_note }}
            </p>
          </div>
          <div class='hauler-indicator' v-show="hasHaulerIndicator">
            <span class="dispatcher-green" v-show="dumpster.portal_active">
              <i class="material-icons">star</i>
              Dispatcher Hauler
            </span>
            <span v-show='dumpster.portal_active && dumpster.region_we_win_winter'> | </span>
            <span v-show='dumpster.region_we_win_winter' class='we-win-winter'>❄️ We Win Winter Partner</span>
          </div>
          <div class="col s12">
            <div class="avail-bar">
              <section :class="['next-avail-group', { bad: nextDeliverable.no_service }]">
                <div class="next-avail">
                  <span
                    v-show="!(nextDeliverable.no_service || nextDeliverable.hasServiceExceptions) && (nextDeliverable.must_call || nextDeliverable.current_must_call || nextDeliverable.must_call_all)"
                    class="tooltipped must-call"
                    :data-tooltip="nextDeliverable.must_call_text ? `${nextDeliverable.phone} | ${nextDeliverable.must_call_text}` : nextDeliverable.phone"
                  >
                    <i class="material-icons bad">
                      local_phone
                    </i>
                  </span>
                  <span
                    :class="{ tooltipped: nextDeliverable.notes }"
                    :data-tooltip="nextDeliverable.notes"
                  >
                    <i
                      :class="[
                        'material-icons',
                        {
                          good: nextDeliverable.notes === '' && !nextDeliverable.no_service && !nextDeliverable.must_call && !nextDeliverable.current_must_call && !nextDeliverable.must_call_all,
                          'more-info': nextDeliverable.notes || nextDeliverable.must_call || nextDeliverable.current_must_call || nextDeliverable.must_call_all || nextDeliverable.exceptionsString.length > 0,
                          bad: nextDeliverable.no_service || nextDeliverable.hasServiceExceptions
                        }
                      ]"
                    >
                      <span v-if='nextDeliverable.no_service'>event_busy</span>
                      <span v-else>today</span>
                    </i>
                  </span>
                  <span v-if="!nextDeliverable.no_service && !nextDeliverable.hasServiceExceptions" class="ml">
                    <strong>Next Available Delivery</strong>:
                    <span v-if='!nextDeliverable.current_must_call'>
                      {{ nextDeliverable.friendlyText }}<span v-if="nextDeliverable.windowInfo.active"> | 1-{{ nextDeliverable.windowInfo.days }} day window</span>
                    </span>
                    <span v-else class="bad">
                      Must Call
                    </span>
                  </span>
                  <span v-if="nextDeliverable.no_service && !nextDeliverable.hasServiceExceptions" class='ml bad'>
                    <strong>No Delivery Service - </strong>
                    {{nextDeliverable.no_service_reason}}
                  </span>
                  <span v-if="nextDeliverable.hasServiceExceptions" :class="{'bad ml': true, 'tooltipped': nextDeliverable.actionMessage}" :data-tooltip="nextDeliverable.actionMessage">
                    <strong>No Delivery Service</strong>
                    <span v-if="nextDeliverable.noServiceException.reason"> - {{nextDeliverable.noServiceException.reason.charAt(0).toUpperCase() + nextDeliverable.noServiceException.reason.substring(1)}}</span>
                    <span v-if="nextDeliverable.actionMessage" class="bad">
                      &nbsp;<i class="material-icons">help</i>
                    </span>
                  </span>
                </div>
                <div v-if='!nextDeliverable.no_service && !nextDeliverable.hasServiceExceptions && nextDeliverable.exceptionsString.length > 0' :class="{'tooltipped': nextDeliverable.actionMessage, 'next-avail-exceptions': true, 'more-info': true}" :data-tooltip="nextDeliverable.actionMessage">
                  {{ nextDeliverable.exceptionsString }}
                  <i v-if="nextDeliverable.actionMessage" class="material-icons">help</i>
                </div>
              </section>
              <section class="sat-sun">
                <div class="saturday">
                  <span
                    :class="[{
                      good: nextDeliverable.saturday.canSchedule && !(nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall),
                      'more-info': nextDeliverable.saturday.canSchedule && (nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall),
                      tooltipped: weekendTooltip()
                    }]"
                      :data-tooltip="weekendTooltip()"
                  >
                    Saturday
                  </span>

                  <span
                    :class="[{
                      good: nextDeliverable.saturday.canSchedule && !(nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall),
                      'more-info': nextDeliverable.saturday.canSchedule && (nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall),
                      tooltipped: weekendTooltip()
                    }]"
                      :data-tooltip="weekendTooltip()"
                  >
                    <i class="material-icons" v-show="nextDeliverable.saturday.enabled && !(nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall)">
                      check_circle
                    </i>
                    <i class="material-icons" v-show="nextDeliverable.saturday.enabled && (nextDeliverable.saturday.notes || nextDeliverable.saturday.mustCall)">
                      help
                    </i>
                    <i class="material-icons"
                      v-show="!nextDeliverable.saturday.enabled">
                      block
                    </i>
                  </span>

                  <span
                    class="material-icons bad tooltipped"
                    :data-tooltip="nextDeliverable.phone">
                    <i v-show="nextDeliverable.saturday.mustCall">
                      local_phone
                    </i>
                  </span>
                </div>
                <div class="sunday">
                  <span
                    :class="[{
                      good: nextDeliverable.sunday.canSchedule && !(nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall),
                      'more-info': nextDeliverable.sunday.canSchedule && (nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall),
                      tooltipped: weekendTooltip(true)
                    }]"
                    :data-tooltip="weekendTooltip(true)"
                  >
                    Sunday
                  </span>

                  <span
                    :class="[{
                      good: nextDeliverable.sunday.canSchedule && !(nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall),
                      'more-info': nextDeliverable.sunday.canSchedule && (nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall),
                      tooltipped: weekendTooltip(true)
                    }]"
                    :data-tooltip="weekendTooltip(true)"
                  >
                    <i class="material-icons" v-show="nextDeliverable.sunday.enabled && !(nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall)">
                      check_circle
                    </i>
                    <i class="material-icons" v-show="nextDeliverable.sunday.enabled && (nextDeliverable.sunday.notes || nextDeliverable.sunday.mustCall)">
                      help
                    </i>
                    <i class="material-icons"
                      v-show="!nextDeliverable.sunday.enabled">
                      block
                    </i>
                  </span>

                  <span
                    class="material-icons bad tooltipped"
                    :data-tooltip="nextDeliverable.phone">
                    <i v-show="nextDeliverable.sunday.mustCall">
                      local_phone
                    </i>
                  </span>
                </div>
              </section>
           </div>

            <ul class="collapsible" data-collapsible="expandable">
              <li>
                <div class="collapsible-header active">
                  <i class="material-icons">add</i>
                  All Service Availability Info
                </div>
                <availability-accordion
                  :availability="availability()"
                />
              </li>
              <li v-if="dumpster.permit_info">
                <div class="collapsible-header">
                  <i class="material-icons">add</i>
                  Permit Info
                </div>
                <div v-html="dumpster.permit_info" class="collapsible-body"></div>
              </li>
              <li v-if="hasGeneralNotes">
                <div class="collapsible-header">
                  <i class="material-icons">add</i>
                  General Notes
                  <i class="material-icons" v-show="dumpster.region_notes">warning</i>
                </div>
                <div class="collapsible-body">
                  <b>General Notes: </b>
                  <span v-html="decode(decode(dumpster.region_notes)) || 'none'"></span>
                  <br>
                  <b>Service Area Defined: </b>
                  <span v-html="decode(decode(dumpster.service_area_defined)) || 'none'"></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div><!--dumpster-price-->

    </section>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import {
  capitalize,
  isEmpty
} from 'kyanite'
import { AllHtmlEntities } from 'html-entities'
import { addDays, endOfDay, setHours, setMinutes } from 'date-fns'
import ClipboardJS from 'clipboard'
import PriceCalculator from './PriceCalculator.vue'
import UserName from './UserName'
import AlertBox from '../ui/AlertBox.vue'
import AvailabilityAccordion from './AvailabilityAccordion.vue'
import BaseModal from '../ui/BaseModal.vue'
import toNormalCase from '../filters/toNormalCase'
import twelveHours from '../filters/time'
import actionLogger from '../services/actionLogging'
import { daysDiff, daysOfWeek, format } from '../utils/time'

/**
 * The component for displaying a standard roll-off dumpster
 * along with it's pricing and related terms
 */
export default {
  name: 'DumpsterPrice',
  props: {
    /**
     * The netsuite hauler name, used for copy to clipboard
     * @type {String}
     */
    haulerName: {
      required: false,
      type: String
    },
    /**
     * The dumpster record from the database to display
     * @type {Object}
     */
    dumpster: {
      required: true,
      type: Object
    }
  },
  filters: {
    capitalize,
    toNormalCase,
    twelveHours,
    convertYard (val) {
      return val === 'yard_waste' ? 'Yard Waste' : val
    }
  },
  components: {
    AlertBox,
    UserName,
    BaseModal,
    PriceCalculator,
    AvailabilityAccordion
  },
  data () {
    return {
      alertDismissed: false,
      filtered: null,
      permitNotes: null,
      showCalculator: false,
      specialUseFields: [
        'concrete',
        'dirt',
        'brick',
        'block',
        'gravel',
        'asphalt',
        'leed',
        'yard_waste',
        'stumps'
      ],
      fillExceptionNoteNames: [
        'yard_waste',
        'stumps',
        'appliances',
        'electronics',
        'mattress',
        'other'
      ],
      class: 'block'
    }
  },
  methods: {
    ...mapActions('map', ['toggleHover']),
    ...mapMutations('events', ['runFlash']),
    ...mapGetters('exceptions', ['getServiceTypeDictionary']),
    isEmpty,
    fmt (val) {
      return format(val, 'MM/DD/YYYY h:mm:ss A')
      // return format(val, 'MM/DD/YYYY')
    },
    customizePrice () {
      this.showCalculator = true
    },
    getServiceTypes () {
      return {
        newDeliveries: 'New Deliveries',
        emptyReturn: 'Empty & Return',
        pickUp: 'Pickup'
      }
    },
    formatDate (date, formatString) {
      if (!date) return date
      return format(new Date(date).toLocaleDateString('en-US'), formatString)
    },
    formatDateData (exceptions, showReason) {
      exceptions.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
      let availabilityString = ''
      let earliestDate = null
      exceptions.forEach(exception => {
        if (new Date(exception.end_date + ' 23:59:59') >= new Date()) {
          if (earliestDate === null) earliestDate = new Date(exception.start_date)
          if (exception.start_date === exception.end_date) {
            availabilityString += `${this.formatDate(exception.start_date + ' 23:59:59', 'MM/DD')}`
          } else {
            availabilityString += `${this.formatDate(exception.start_date + ' 23:59:59', 'MM/DD')} - ${this.formatDate(exception.end_date + ' 23:59:59', 'MM/DD')}`
          }

          if (showReason) availabilityString += ` (${exception.reason}), `
          else availabilityString += ', '
        }
      })
      availabilityString = availabilityString.slice(0, -2)
      return {
        availabilityString,
        earliestDate
      }
    },
    formatDaysOfWeek (exceptions) {
      const days = []
      exceptions.forEach(exception => {
        days.push(...exception.days_of_week)
      })

      return daysOfWeek(days).map((day) => {
        return day.namePlural
      }).join(', ')
    },
    formatExceptionsString (availabilityByDateExceptions, closureExceptions, dayOfWeekExceptions) {
      const messages = []
      const dateData = this.formatDateData(availabilityByDateExceptions, false)
      const closureData = this.formatDateData(closureExceptions, false)
      const daysOfWeekData = this.formatDaysOfWeek(dayOfWeekExceptions)

      if (closureData.availabilityString !== '') {
        messages.push(`Hauler Closed ${closureData.availabilityString}`)
      }

      if (dateData.availabilityString !== '') {
        const msg = `Do NOT Schedule ${dateData.availabilityString}`

        if (dateData.earliestDate < closureData.earliestDate) {
          messages.unshift(msg)
        } else {
          messages.push(msg)
        }
      }

      if (daysOfWeekData) {
        messages.push(`Do NOT schedule on ${daysOfWeekData}`)
      }

      return messages.join(' | ')
    },
    dateFmt (val) {
      return format(val, 'MM/DD/YYYY')
    },
    logInteraction () {
      const { firstName, lastName } = this.user
      const data = {
        search_id: this.dumpster.search_id,
        hauler_name: this.dumpster.hauler_name,
        region_name: this.dumpster.region_name,
        size: this.dumpster.size.name,
        waste_type: this.dumpster.waste_type.name,
        sell_as: this.dumpster.sell_as,
        price: this.price,
        mako_preferred: this.dumpster.mako_preferred,
        preferred: this.dumpster.preferred,
        preferred_rank: this.dumpster.preferred_rank,
        rank: this.dumpster.rank,
        cost_structure: this.dumpster.cost_structure.name,
        tonnage: this.dumpster.tonnage,
        netsuite_id: this.dumpster.netsuite_id,
        salesforce_id: this.dumpster.salesforce_id,
        mako_id: this.dumpster.id,
        netsuite_item_name: this.dumpster.netsuite_item_name,
        profitability: this.dumpster.profitability,
        current_availability: this.dumpster.current_availabililty,
        searched_by: `${firstName} ${lastName}`
      }
      return actionLogger.logAzure('conversions', data)
    },
    decode: val => AllHtmlEntities.decode(val),
    /**
     * @name fillExceptionClass
     * @description Class binding to set appropriate text color
     * @param {string} type - the exception property to check
     * @return {string} - yes or no background and tooltip where necessary
     */
    fillExceptionClass (type) {
      const willAccept = !!this.dumpster[type]
      const hasTooltip = !isEmpty(this.dumpster[`${type}_note`]) && willAccept
      let acceptance = willAccept ? 'yes' : 'no grey-text'
      acceptance = hasTooltip ? `${acceptance} exception tooltipped` : acceptance
      return acceptance
    },
    formatTime (time) {
      if (!time) {
        return time
      }

      const [hour, minute] = time.split(':')

      return format(setHours(setMinutes(new Date(), minute), hour), 'h:mma')
    },
    weekendTooltip (sunday) {
      const day = sunday ? 'sunday' : 'saturday'
      const data = this.nextDeliverable[day]
      const cutoff = data.cutoff

      if (!data.enabled) {
        return ''
      }

      // Set default values
      cutoff.day = cutoff.day || 'Friday'
      if (!cutoff.time || cutoff.time === '00:00:00') {
        cutoff.time = '23:59:59'
      }

      // Compose the tooltip
      let note = `Cutoff: ${cutoff.day} ${this.formatTime(cutoff.time)}`
      if (data.notes) {
        note += ' | ' + data.notes
      }

      return note
    },
    getMustCallText (reason) {
      return (reason !== 'Exception') ? reason : ''
    },
    getMustCallReason (contract, current, availabilityDates, hasMustCallZoneException) {
      if (current.must_call) {
        return (current.size && current.size.name) ? current.size.name : ''
      }

      if (!availabilityDates.nextAvailable.date) {
        return availabilityDates.nextAvailable.friendlyText
      }

      // Compare day of month only, not time of day
      const today = endOfDay(new Date())
      const nextAvailable = endOfDay(new Date(availabilityDates.nextAvailable.date + ' 12:00:00'))
      let days = (nextAvailable.getTime() >= today.getTime()) ? daysDiff(today, nextAvailable) : -1

      // Ignore weekend days
      if (
        [4, 5, 6, 0].includes(today.getDay()) &&
        [1, 2, 3].includes(nextAvailable.getDay())
      ) {
        days = Math.max(0, days - 2)
      }

      // bitwise comparison for which phrases to use
      const SAME_DAY = 1
      const NEXT_DAY = 2
      const TWO_DAY = 4

      let possibleNotices = 0
      switch (days) {
        case 0:
          possibleNotices |= SAME_DAY
          // fall through

        case 1:
          possibleNotices |= NEXT_DAY
          // fall through

        case 2:
          possibleNotices |= TWO_DAY
          break
      }

      const phrases = []
      if (contract.same_day_must_call && (possibleNotices & SAME_DAY)) {
        phrases.push('Same Day')
      }
      if (contract.next_day_must_call && (possibleNotices & NEXT_DAY)) {
        phrases.push('Next Day')
      }
      if (contract.two_day_notice_must_call && (possibleNotices & TWO_DAY)) {
        phrases.push('2-Day Notice')
      }

      if (phrases.length) {
        return phrases.join(', ')
      }

      if (hasMustCallZoneException) {
        return 'Exception'
      }
    },
    getCostTooltip (p, c, canUseFlatTax, note) {
      const price = parseFloat(p)
      const taxRate = this.dumpster.tax_rate
      const hasFlatTax = this.dumpster.user_defined_one !== null && this.dumpster.user_defined_one !== ''
      let costString = (typeof taxRate === 'undefined') ? 'Tax information is temporarily unavailable' : 'No Tax'

      /* set tax string

        we should only show flat tax if the dumpster has a flat tax and the tooltip is able to use the flat tax.
        otherwise, we should use a percent tax (if present)
       */
      if (taxRate > 0 && (!hasFlatTax || canUseFlatTax)) {
        let taxAmount = 0
        if (hasFlatTax) {
          taxAmount = parseFloat(this.dumpster.user_defined_one) * parseFloat(taxRate) / 100
          costString = 'Flat Tax | '
        } else {
          taxAmount = price * parseFloat(taxRate) / 100
          costString = `${taxRate}% Tax | `
        }
        const taxTotal = price + taxAmount
        costString += `${p} + ${taxAmount.toFixed(2)} = ${taxTotal.toFixed(2)}`
      }

      // set cost string
      costString += `<br />Cost: $${c}`
      if (c > 0.00) {
        const looseMargin = price - parseFloat(c)
        costString += ` | (+${looseMargin.toFixed(2)})`
      }

      return note
        ? `${costString}<br /> ${note}`
        : `${costString}`
    },
    // This is to emulate the backend data we aren't giving yet
    getAvailabilityDateInfo (info, exception) {
      if (typeof exception === 'undefined' || exception.availability_action.toLowerCase() === 'mustcall') {
        return info
      }

      const dateFormat = 'YYYY-MM-DD'
      const now = new Date()
      const date = new Date(exception.next_available_date + ' ' + format(now, 'hh:mm:ss'))

      const formatted = format(date, dateFormat)
      let friendly = format(date, 'dddd M/D')
      let friendlyAbbr = format(date, 'ddd M/D')

      if (format(now, dateFormat) === formatted) {
        friendly = 'Today'
        friendlyAbbr = 'Today'
      }
      if (format(addDays(now, 1), dateFormat) === formatted) {
        friendly = 'Tomorrow'
        friendlyAbbr = 'Tomorrow'
      }

      return {
        date: formatted,
        friendlyText: friendly,
        friendlyAbbreviated: friendlyAbbr
      }
    },
    getAvailability (availabilityType) {
      // shallow copy of availability
      let avail = [...this.dumpster.current_availability]
      const contractAvail = this.dumpster.region_availability[availabilityType]
      avail = avail.filter(i => i.delivery_type === availabilityType)
      const currentAvail = { ...avail }[0]
      const record = availabilityType === 'newDeliveries' ? currentAvail[availabilityType].filter(i => i.size_id === this.dumpster.size_id)[0] : currentAvail[availabilityType]
      const sizeRecord = { ...record }
      const twoDayNotice = sizeRecord.window_active && contractAvail.two_day_notice_must_call
      const availabilityName = twoDayNotice ? '2-Day Notice' : (sizeRecord.delivery_date.availabilityType || '')
      const prefix = twoDayNotice ? 'two_day_notice' : availabilityName.toLowerCase().replace(/\s/g, '_')
      const mustCallAll = this.dumpster.region_availability[availabilityType].must_call_all
      const phone = this.dumpster.region_phone || 'No Phone Provided'
      const notes = `${prefix}_notes`

      // the exception type is cased different from the availability type for pickup
      const exceptionType = availabilityType === 'pickUp' ? 'pickup' : availabilityType

      const filterExceptions = (exceptions, dumpster, useSellAsSize) => {
        return exceptions.filter((item) => {
          const size = (useSellAsSize && dumpster.sell_as)
            ? dumpster.sell_as
            : dumpster.size_id

          if (item.delivery_type.indexOf(exceptionType) < 0) {
            return false
          }

          if (item.sizes !== '' && item.sizes.indexOf(size) < 0) {
            return false
          }

          if (item.waste_types !== '' && item.waste_types.indexOf(dumpster.waste_type_id) < 0) {
            return false
          }

          return !(item.service_areas !== '' && item.service_areas.indexOf(dumpster.service_area_id) < 0)
        })
      }

      const sortedExceptions = [...this.dumpster.region_exceptions].sort((a, b) => {
        if (a.updated_at > b.updated_at) {
          return -1
        }

        if (a.updated_at < b.updated_at) {
          return 1
        }

        return 0
      })

      const filteredExceptions = filterExceptions(sortedExceptions, this.dumpster)

      const availabilityByDateExceptions = filteredExceptions.filter((item) => (item.type === 'availabilityByDate'))

      const closureExceptions = filteredExceptions.filter((item) => (item.type === 'closure'))

      const dayOfWeekExceptions = filteredExceptions.filter((item) => (item.type === 'dayOfWeek'))

      const windowExceptions = filteredExceptions.filter((item) => (item.type === 'window'))

      const availabilityZoneExceptions = filteredExceptions.filter((item) => {
        const serviceAreaId = this.dumpster.service_area_id
        const sizeId = this.dumpster.size_id
        const serviceAreaExceptions = item.service_areas
        const sizeExceptions = item.sizes

        return item.type === 'availabilityByZone' &&
          (serviceAreaExceptions === '' || serviceAreaExceptions.indexOf(serviceAreaId) > -1) &&
          (sizeExceptions === '' || sizeExceptions.indexOf(sizeId) > -1)
      })

      let hasMustCallZoneException = false
      if (availabilityZoneExceptions.length) {
        hasMustCallZoneException = availabilityZoneExceptions.reduce(
          (result, exception) => {
            return result || (exception.availability_action.toLowerCase() === 'mustcall')
          },
          hasMustCallZoneException
        )
      }

      let serviceAreaExceptionInfo = {}
      const serviceAreaExceptions = filteredExceptions.filter((item) => item.type === 'serviceArea') || []
      if (serviceAreaExceptions.length) {
        const recentServiceAreaException = serviceAreaExceptions.reduce((result, item) => item.updated_at > result.updated_at ? item : result)
        serviceAreaExceptionInfo = {
          description: serviceAreaExceptions.map(item => item.boundaries_description).sort().join(' | '),
          updated_date: this.dateFmt(recentServiceAreaException.updated_at)
        }
      }

      // service exception
      const noServiceExceptions = filterExceptions(sortedExceptions, this.dumpster, true).filter((item) => (item.type === 'noService'))
      const hasServiceExceptions = (noServiceExceptions.length > 0)
      let noServiceException = {}
      if (hasServiceExceptions) {
        noServiceException = noServiceExceptions[0]
      }

      let actionMessage = ''
      if (hasServiceExceptions) {
        const lastUpdated = new Date(noServiceException.updated_at)
        actionMessage = 'As of: ' + this.dateFmt(lastUpdated)
      } else {
        const dowExceptionDates = { created: new Date(), end: new Date(), perm: false }
        dayOfWeekExceptions.map((exception) => {
          dowExceptionDates.perm = dowExceptionDates.perm || (exception.action_type === 'permanent')

          const exceptionDate = new Date(exception.created_at)
          if (exceptionDate < dowExceptionDates.created) {
            dowExceptionDates.created = exceptionDate
          }

          if (exception.action_date) {
            const exceptionDate = new Date(exception.action_date)
            if (exceptionDate > dowExceptionDates.end) {
              dowExceptionDates.end = exceptionDate
            }
          }
        })

        if (dayOfWeekExceptions.length) {
          actionMessage = 'As of: ' + this.dateFmt(dowExceptionDates.created)
        }
      }

      let mustCallAllText = ''
      switch (availabilityType) {
        case 'newDeliveries':
          mustCallAllText = 'All Deliveries'
          break
        case 'emptyReturn':
          mustCallAllText = 'All Empty & Returns'
          break
        case 'pickUp':
          mustCallAllText = 'All Pickups'
          break
      }

      // When backend is ready, just do: availabilityDates = this.dumpster.availability[availabilityType] || {}
      const availabilityDates = { nextAvailable: this.getAvailabilityDateInfo(sizeRecord.delivery_date, availabilityZoneExceptions[0]) }

      const mustCallReason = this.getMustCallReason(contractAvail, sizeRecord, availabilityDates, hasMustCallZoneException)
      const mustCallText = mustCallAll
        ? mustCallAllText
        : this.getMustCallText(mustCallReason)

      const serviceTitle = this.getServiceTypes()[availabilityType]

      return {
        title: (this.dumpster.region_availability[availabilityType].no_service || hasServiceExceptions) ? `No ${serviceTitle} Service -` : serviceTitle + ':',
        type: availabilityType,
        phone,
        active: sizeRecord[`${prefix}_active`],
        must_call: !!mustCallText,
        must_call_text: mustCallText,
        must_call_all: mustCallAll,
        current_must_call: hasMustCallZoneException || sizeRecord.must_call,
        contract_must_call: contractAvail[`${prefix}_must_call`],
        friendlyText: availabilityDates.nextAvailable.friendlyAbbreviated,
        friendlyTextAbbreviated: availabilityDates.nextAvailable.friendlyAbbreviated,
        notes: contractAvail[notes] || '',
        cutoff_times: {
          same_day_cutoff_time: sizeRecord.same_day_cutoff_time,
          next_day_cutoff_time: sizeRecord.next_day_cutoff_time,
          two_day_notice_cutoff_time: sizeRecord.days_out_cutoff_time
        },
        saturday: {
          enabled: sizeRecord.saturday_active || contractAvail.saturday_active,
          canSchedule: sizeRecord.saturday_active && !this.pastCutoff(sizeRecord.saturday_cutoff_day, sizeRecord.saturday_cutoff_time, this.currentDate),
          mustCall: contractAvail.saturday_must_call,
          cutoff: {
            day: sizeRecord.saturday_cutoff_day,
            time: sizeRecord.saturday_cutoff_time
          },
          notes: contractAvail.saturday_notes
        },
        sunday: {
          enabled: sizeRecord.sunday_active || contractAvail.sunday_active,
          canSchedule: sizeRecord.sunday_active && !this.pastCutoff(sizeRecord.sunday_cutoff_day, sizeRecord.sunday_cutoff_time, this.currentDate),
          mustCall: contractAvail.sunday_must_call,
          cutoff: {
            day: sizeRecord.sunday_cutoff_day,
            time: sizeRecord.sunday_cutoff_time
          },
          notes: contractAvail.sunday_notes
        },
        no_service: this.dumpster.region_availability[availabilityType].no_service,
        no_service_reason: this.dumpster.region_availability[availabilityType].no_service_reason,
        noServiceException: noServiceException || {},
        hasServiceExceptions,
        exceptionsString: this.formatExceptionsString(availabilityByDateExceptions, closureExceptions, dayOfWeekExceptions),
        serviceAreaExceptionInfo,
        actionMessage,
        windowInfo: this.getWindowInfo(currentAvail, contractAvail, windowExceptions),
        containerClass: availabilityType === 'emptyReturn' ? 'white' : 'light-gray'
      }
    },
    pastCutoff (day, time, curDate) {
      day = day || 'Friday'

      // Use Sunday as 7 for easier comparison
      const cutoffDayOfWeek = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(day)
      const currentDayOfWeek = curDate.getDay() || 7

      if (cutoffDayOfWeek < currentDayOfWeek) {
        return true
      }

      if (cutoffDayOfWeek === currentDayOfWeek) {
        // compare time if we are on the cutoff day
        const availDate = new Date()
        const [availHour, availMinute] = time.split(':')
        availDate.setHours(availHour, availMinute)

        if (curDate.getTime() >= availDate.getTime()) {
          return true
        }
      }

      return false
    },
    getWindowInfo (currentAvail, contractAvail, windowExceptions) {
      let active = false
      let days = null
      let contractDays = 0
      let currentDays = 0
      if (contractAvail.window_active) {
        active = true
        contractDays = contractAvail.window_days
      }
      if (currentAvail.window_active && currentAvail.window_days !== null) {
        active = true
        currentDays = currentAvail.window_days
      }
      days = Math.max(contractDays, currentDays)

      if (windowExceptions) {
        const exceptionDays = windowExceptions.reduce((carry, exception) => {
          const key = exception.bulk ? 'bulk' : 'local'
          carry[key] = Math.max(carry[key], exception.window_days)

          return carry
        }, { bulk: 0, local: 0 })

        if (exceptionDays.local > 0 || exceptionDays.bulk > 0) {
          active = true
          days = Math.max(exceptionDays.bulk, exceptionDays.local, contractDays, currentDays)
        }
      }

      return { active, days }
    },
    availability () {
      return {
        newDeliveries: this.getAvailability('newDeliveries'),
        emptyReturn: this.getAvailability('emptyReturn'),
        pickUp: this.getAvailability('pickUp')
      }
    }
  },
  computed: {
    ...mapState('session', ['preferences', 'user']),
    ...mapState('search', ['placeFormattedAddress', 'searchSessionId']),
    ...mapGetters('time', ['currentDate']),
    ...mapState(['hover']),
    ...mapState('time', ['timezoneOffset']),
    ...mapState('size', ['sizes']),
    // ...mapGetters('availability', ['filterableAvailabilityNames']),
    canUseCalculator () {
      return this.user.roles.includes('B2B Sales')
    },
    netsuiteName () {
      const { netsuite_item_name, netsuite_name } = this.dumpster // eslint-disable-line camelcase
      return (netsuite_item_name) || netsuite_name // eslint-disable-line camelcase
    },
    nextDeliverable () {
      return this.getAvailability('newDeliveries')
    },
    hasGeneralNotes () {
      return !isEmpty(this.dumpster.region_notes) || !isEmpty(this.dumpster.service_area_defined)
    },
    hasSpecialDebris () {
      return Boolean(this.dumpster.waste_type.special_debris)
    },
    profitMargin () {
      return parseFloat(this.dumpster.profitability).toFixed(2)
    },
    quantitySold () {
      return parseInt(this.dumpster.sold_qty_last_45)
    },
    pricingByType () {
      return (this.dumpster.pricing?.ancillaries ?? []).reduce((ancillaries, ancillary) => {
        ancillaries[ancillary.price_type_name] = ancillary
        return ancillaries
      }, {})
    },
    price () {
      const price = parseFloat(this.pricingApiFeatureEnabled ? this.dumpster.pricing?.price : this.dumpster.price)
      if (isNaN(price)) return null
      return price
    },
    priceUsd () {
      if (this.price == null) return null
      return `$${this.price.toFixed(2)}`
    },
    overagePrice () {
      const price = parseFloat(this.pricingApiFeatureEnabled ? this.pricingByType['Overage Revenue']?.price : this.dumpster.price_per_ton)
      if (isNaN(price)) return null
      return price
    },
    overagePriceFixed () {
      if (this.overagePrice == null) return null
      return this.overagePrice.toFixed(2)
    },
    extensionPrice () {
      const price = parseFloat(this.pricingApiFeatureEnabled ? this.pricingByType['Extension Revenue']?.price : this.dumpster.extra_day_price)
      if (isNaN(price)) return null
      return price
    },
    extensionPriceFixed () {
      if (this.extensionPrice == null) return null
      return this.extensionPrice.toFixed(2)
    },
    costByType () {
      return (this.dumpster.pricing?.cost?.ancillaries ?? []).reduce((ancillaries, ancillary) => {
        ancillaries[ancillary.price_type_name] = ancillary
        return ancillaries
      }, {})
    },
    overageCost () {
      const cost = parseFloat(this.pricingApiFeatureEnabled ? this.costByType['Overage Expense']?.price : this.dumpster.cost_per_ton)
      if (isNaN(cost)) return null
      return cost
    },
    extensionCost () {
      const cost = parseFloat(this.pricingApiFeatureEnabled ? this.costByType['Extension Expense']?.price : this.dumpster.extra_day_cost)
      if (isNaN(cost)) return null
      return cost
    },
    pricingApiFeatureEnabled () {
      return (process.env.VUE_APP_MAKO_FEATURE_PRICING_API ?? '').toLowerCase() === 'true'
    },
    hasHaulerIndicator () {
      return this.dumpster.portal_active || this.dumpster.region_we_win_winter
    }
  },
  updated () {
    const clipboard = new ClipboardJS('.clip-btn')
    clipboard.on('success', e => {
      const options = {
        message: `Copied ${e.text} to clipboard`,
        severity: 'success',
        timeout: 1500
      }
      this.runFlash(options)
    })

    clipboard.on('error', e => {
      const options = {
        message: `Error copying ${e.text} to clipboard`,
        severity: 'error',
        timeout: 1500
      }
      this.runFlash(options)
    })
  },
  beforeUnmount () {
    // close the modal
    this.showCalculator = false
  },
  mounted () {
    $('.collapsible').collapsible()
    $('.tooltipped').tooltip()
    const clipboard = new ClipboardJS(`.clip-btn-${this.dumpster.id}`)
    clipboard.on('success', e => {
      const options = {
        message: `Copied ${e.text} to clipboard`,
        severity: 'success',
        timeout: 1500
      }
      this.logInteraction()
      this.runFlash(options)
    })

    clipboard.on('error', e => {
      const options = {
        message: `Error copying ${e.text} to clipboard`,
        severity: 'error',
        timeout: 1500
      }
      this.runFlash(options)
    })
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';
section.price-item {
   min-height: 197px;
   box-shadow: 0 2px 2px rgba(85,85,85,0.5);
}
[v-cloak] {
  display: none
}

a {
  color: $primary;
  display: block;
  margin-top: 1em;
  cursor: pointer;
}

.material-icons:hover {
  cursor: pointer;
}
.updated-tag {
  color: $dark-gray;
  text-shadow: none;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.ml {
  margin-left: 0.5rem;
}

.more-info {
  color: $alert !important;
}

.good {
  color: $success-dark !important;
}

.bad {
  color: $danger !important;
}

.copy-update {
  .btn {
    background-color: $primary;
    height: auto;
    &:hover {
      background-color: $primary-light;
    }
    &.yellow-btn {
      color: black;
      background-color: #ffcf02;
      i.material-icons {
        line-height: normal;
        position: relative;
        top: 5px;
        right: 5px;
        font-size: 22px;
      }
      &:hover {
        background-color: lighten(#ffcf02, 15%);
      }
    }
  }
}

.clip-btn {
  font-size: 12px;
  background: lighten($light-gray, 20%);
  color: $gray;
}

.color-tab {
    width: 40px;
    height: 100%;
    margin: 0 5px 0 0;
}

.btn {
  font-size: 12px;
  padding: 0 1rem;
}

.hauler-info-header {
    background-color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 5px;
    width: 100%;
    .title-left {
      display: flex;
      justify-content: flex-start;
      .ns-name {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .gross-margin {
      font-size: 15px;
      align-self: flex-end;
      margin: 0 5px 0 0;
    }
}

.service-area-alert {
    background-color: $danger;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 5px;
    width: 100%;

    .details {
      font-size: 15px;
      align-self: flex-end;
      margin: 0 5px 0 0;
    }
}

.grid {
  display: grid;
  &.two-col,.two-col {
    grid-template-columns: repeat(2, minmax(50px, 1fr))
  }
  &.three-col {
    grid-template-columns: repeat(3, minmax(50px, 1fr))
  }
  &.four-col {
    grid-template-columns: repeat(4, minmax(50px, 1fr))
  }
  &.five-col {
    grid-template-columns: repeat(5, minmax(50px, 1fr))
  }
}

.avail-bar {
  display: grid;
  grid-template-columns: 65% 35%;
  border: 1px solid $light-gray;
  font-size: 14px;
  align-content: center;

  .material-icons {
    font-size: 18px;
  }

  .next-avail-group {
    padding: 0.5rem;
    white-space: pre-wrap;

    .must-call {
      padding-right: 8px;
    }

    span {
      display: flex;
      align-self: center;
      align-content: center;
    }

    .material-icons {
      align-self: center;
    }
  }

  .next-avail {
    display: flex;
  }

  .next-avail-exceptions {
    font-size: 12px;
  }

  .sat-sun {
    display: flex;
    vertical-align: center;
    border-left: 1px solid $light-gray;
    padding: 0.5rem;

    .saturday,
    .sunday {
      color: $gray;
      align-self: center;
    }

    .saturday {
      display: flex;
      line-height: normal;
      margin-right: 0.75rem;
    }
    .sunday {
      display: flex;
      line-height: normal;
    }
    .material-icons {
      margin-left: 2px;
    }
  }
}

.alert-accordion {
  margin-top: 0;
  box-shadow: none;
  background-color: $alert-light;

  .collapsible-header {
    padding: 0 !important;
    background-color: $alert-light !important;
  }
  .collapsible-body {
    color: black;
    padding: 15px;
  }
}

.alert-box {
  &.note {
    color: rgb(238, 238, 18);
  }
}

.primary-alert-text {
  font-size: 1rem;
  text-align: center;
}
.details, .note {
  text-align: center;
}

.outOfNetwork {
  color: darkorange;
}

.dumpster-price {
  &.primary {
      .dumpster-size {
          background-clip: content-box;
          color: $success-dark;
      }
      .waste-type {
          background-color: $success-dark;
      }
      .dumpster-price-body {
          background-clip: content-box;
      }
      .rank {
        color: $success-dark;
      }
      .updated-tag {
        color: $dark-gray;
      }
  }

  .cost-info {
    margin: 15px;
  }

  .dumpster-price {
    font-weight: bold;
  }
  .dumpster-size {
    position: relative;
      background-clip: content-box;
      color: $dark-gray;
      background-color: $light-gray;
      text-shadow: 2px 2px $light-gray;
      .spacer {
        margin-bottom: 20px
      }
      .sale-volume {
          font-size: 2rem;
          background-color: $white;
          padding: 10px 0;
      }
      .actual-volume,
      .custom-dimensions {
          font-size: 13px;
      }
  }

  .service-info-outline {
    border: 1px solid $light-gray;
    color: $dark-gray;
    padding: 5px;
    .service-name {
      text-align: center;
      background-color: $dark-gray;
      color: white;
      margin: 0;
      padding: 0;
    }
    div.service-avail-list:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }
    .service-avail {
      color: $dark-gray;
      font-weight: bold;
    }
  }

  .dumpster-price-body {
    color: $dark-gray;
    background-clip: content-box;
    background-color: $white;
    min-height: 197px;
    position: relative;
    .row {
      margin-bottom: 8px;
    }

    .alert-box.note {
      font-size: 1rem;
    }

    ul.collapsible {
      margin-top: 0;
      padding-top: 0;
    }

    .scan-bar {
      text-align: center;
      font-size: 12px;
      div {
        padding: 5px 0 5px 0;
        border: 1px solid $light-gray;
        &:after{
          font-size: 16px;
          position: relative;
          top: 3px;
        }
      }
    }
    .context.overage {
      &:after {
        font-family: 'Material Icons';
        content: 'help';
      }
    }

    .yes {
      color: $success-dark;
      &:after {
        font-family: 'Material Icons';
        content: 'check_circle'
      }
      &.exception,
      &.unverified {
        color: $alert;
        &:after {
          font-family: 'Material Icons';
          content: 'help';
        }
      }
    }
    .no {
      color: $light-gray;
      &:after {
        font-family: 'Material Icons';
        content: 'block';
      }
    }
  }

  .waste-type {
    background-color: $dark-gray;
    color: $white;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    text-shadow: none;
    padding: 5px 0;
    margin: 0 0 10px 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    &.primary {
      background-color: $success;
    }
  }
  .collapsible-header, .collapsible-body {
    padding: .25rem;
    overflow: auto;
  }
  .collapsible-header {
    background-color: $gray;
    color: $white;
  }
}
.product-info {
    color: $dark-gray;
    margin: 0 auto;
    .material-icons,
    .fa {
        font-size: 1.5rem !important;
    }
}
.hauler-indicator {
  color: $gray;
  font-size: 12px;
  line-height:14px;
  padding: 4px 8px;
  font-weight: bold;
  i.material-icons {
    font-size: 12px;
    line-height: 14px;
  }
}
.dispatcher-green {
  color: $dispatcher-green;
}
.cost-info img {
  height: 1em;
}

.pricing-error {
  color: red;
  display: block;
}

.we-win-winter {
  color: #98CFE5;
}
</style>
